import React from 'react';
import { Tooltip } from '@thinkific/toga-react';
import classNames from 'classnames/bind';
import { ButtonWithIconOnly } from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import styles from './AttachmentButtons.module.scss';

const cx = classNames.bind(styles);

export interface CommentAttachmentButtonsProps {
  openAttachment: (attachmentType: string) => () => void;
  buttonsDisabled: boolean;
  attachmentButtonData: Array<{
    tooltipTitle: string; // This could be made consistent with PostAttachmentButtons title and share the interface
    attachmentType: string;
    iconName: string;
    dataQA: string;
  }>;
}

const CommentAttachmentButtons: React.FC<CommentAttachmentButtonsProps> = ({
  openAttachment,
  buttonsDisabled,
  attachmentButtonData,
}) => {
  const buttons = attachmentButtonData.map(
    ({ tooltipTitle, attachmentType, iconName, dataQA }) => (
      <Tooltip
        className={cx('attachment__buttons__comment')}
        key={tooltipTitle}
        title={buttonsDisabled ? '' : tooltipTitle}
      >
        <ButtonWithIconOnly
          appearance="knockout"
          aria-label={tooltipTitle}
          data-qa={dataQA}
          isDisabled={buttonsDisabled}
          onClick={openAttachment(attachmentType)}
        >
          <Icon name={iconName} />
        </ButtonWithIconOnly>
      </Tooltip>
    )
  );
  return <>{buttons}</>;
};

export default CommentAttachmentButtons;
