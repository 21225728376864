import React from 'react';
import classNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';
import styles from './LoomPreview.module.scss';

const cx = classNames.bind(styles);

interface VideoPreviewProps {
  loomId: string;
}

const LoomPreview: React.FC<VideoPreviewProps> = ({ loomId }) => {
  const { t } = useTranslation();
  return (
    <div className={cx('loom-preview')} data-qa="loom-preview">
      <iframe
        className={cx('loom-preview__iframe')}
        src={`https://www.loom.com/embed/${loomId}`}
        title={t(
          'components-attachmentVideoInput-loom_title',
          'Loom Video Preview'
        )}
        allowFullScreen
      />
    </div>
  );
};

export default LoomPreview;
