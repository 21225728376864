import React, { Component } from 'react';
import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import { Caption } from 'koba/components/Typography';
import SimpleFilePicker from 'koba/components/SimpleFilePicker';
import CircularSpinner from 'koba/components/CircularSpinner';
import { withTranslation, TFunction } from 'next-i18next';
import {
  ACCEPTED_FILE_TYPES,
  AWS_S3_URL,
  FILE_TYPES_ARTICLE,
} from 'utils/constants';
import { ClientOptions } from 'filestack-js';
import { WithTranslation } from 'react-i18next';
import { File } from 'types/filestack';
import { Attachment } from 'schema/Post/types';

import styles from './AttachmentFileInput.module.scss';

import FilePreview from './FilePreview';
import ImagePreview from './ImagePreview';
import AttachmentAltTextInput from './AttachmentAltTextInput';

const cx = classNames.bind(styles);

interface AttachmentFileInputProps extends WithTranslation {
  accept?: string;
  altText?: string | null;
  attachment?: Attachment | null;
  buttonLabel: string;
  clientOptions: ClientOptions;
  hasImagePreview?: boolean;
  isPostForm?: boolean;
  maxFileSizeInMb?: number;
  s3UploadPath: string;
  t: TFunction;
  uploadingLabel: string;
  onAltTextChange?: (text: string) => void;
  onChange: (attachment?: Attachment) => void; // attachmentType is set in the AttachmentButtons component
  onClose?: () => void; // This is always provided so doesn't need to be optional?
  onFilePickerOpen?: (input: boolean) => void;
  onUploading?: () => void;
}

type AttachmentFileInputState = {
  errorMessage: string;
  attachment?: Attachment | null;
};

class AttachmentFileInput extends Component<
  AttachmentFileInputProps,
  AttachmentFileInputState
> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    accept: ACCEPTED_FILE_TYPES,
    altText: '',
    attachment: null,
    hasImagePreview: false,
    isPostForm: false,
    maxFileSizeInMb: 5,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
  };

  constructor(props: AttachmentFileInputProps) {
    super(props);
    const { attachment = null } = props;
    this.state = {
      errorMessage: '',
      attachment,
    };
  }

  handleUploadError = (errorMessage) => {
    this.setState({ errorMessage });
  };

  handleUploadSuccess = (file: File) => {
    const { onChange } = this.props;
    const attachment = {
      attachmentType: file.type,
      fileName: file.name,
      fileSizeInBytes: file.size,
      url: `https://${file.container}.${AWS_S3_URL}/${file.key}`,
    };
    this.setState({ errorMessage: '', attachment });

    onChange({
      fileName: file.name,
      fileSizeInBytes: file.size,
      filestackId: file.handle,
      s3Key: file.key,
      s3Bucket: file.container,
      url: `https://${file.container}.${AWS_S3_URL}/${file.key}`,
      // We need to nullify this, as it is only used for video attachments
      attachmentUrl: undefined,
    });
  };

  handleRemoveFile = () => {
    const { onChange } = this.props;
    this.setState({ attachment: null });
    onChange(undefined);
  };

  handleAltTextChange = (value) => {
    const { onAltTextChange } = this.props;
    onAltTextChange?.(value);
  };

  handleUpload = () => {
    const { onUploading, onFilePickerOpen } = this.props;
    onUploading?.();
    onFilePickerOpen?.(false);
  };

  render() {
    const {
      accept,
      buttonLabel,
      clientOptions,
      hasImagePreview,
      maxFileSizeInMb,
      onClose,
      s3UploadPath,
      uploadingLabel,
      t,
      altText,
      isPostForm,
      onFilePickerOpen,
    } = this.props;
    const hideDivider = !!onClose;
    const hideCloseButton = !!onClose;

    const { errorMessage, attachment } = this.state;

    const hasImageAttachmentType =
      attachment &&
      attachment.attachmentType &&
      attachment.attachmentType.match(/image/);

    if (attachment) {
      // @ts-ignore we will only have fileName if an existing image is passed in
      const imageName = attachment.fileName || '';
      // @ts-ignore we will only have fileName if an existing image is passed in
      const fileName = attachment.fileName || '';
      // @ts-ignore we will only have fileName if an existing image is passed in
      const fileSizeInBytes = attachment.fileSizeInBytes || 0;

      return hasImagePreview && hasImageAttachmentType ? (
        <>
          {hideDivider && <hr className={cx('file-input__separator')} />}
          <ImagePreview
            imageName={imageName}
            imageUrl={attachment.url || ''}
            t={t}
            onRemove={this.handleRemoveFile}
          />
          {!isPostForm && (
            <AttachmentAltTextInput
              t={t}
              value={altText || ''}
              onChange={this.handleAltTextChange}
            />
          )}
        </>
      ) : (
        <FilePreview
          fileName={fileName}
          fileSizeInBytes={fileSizeInBytes}
          t={t}
          onRemove={this.handleRemoveFile}
        />
      );
    }

    return (
      !attachment && (
        <SimpleFilePicker
          accept={accept}
          ariaControls="file-attachment-preview"
          awsRegion={process.env.AWS_REGION}
          clientOptions={clientOptions}
          filestackAPIKey={process.env.FILESTACK_API_KEY}
          maxFileSizeInMb={maxFileSizeInMb}
          render={({ openPicker, isUploading }) => {
            const onOpenPicker = () => {
              openPicker();
              onFilePickerOpen?.(true);
            };

            return (
              <div
                className={cx('file-input', {
                  'has-error': errorMessage,
                })}
              >
                {hideCloseButton && (
                  <Button
                    appearance="knockout"
                    ariaLabel={t('Close file input')}
                    className={cx('close-button')}
                    isDisabled={isUploading}
                    onClick={onClose}
                  >
                    <Icon name="x-dismiss" />
                  </Button>
                )}
                <Button
                  appearance="default"
                  className={cx('file-input__upload-button')}
                  data-qa="space-image-upload__button"
                  isDisabled={isUploading}
                  size="small"
                  onClick={onOpenPicker}
                >
                  {isUploading && (
                    <span className={cx('file-input__upload-text')}>
                      <CircularSpinner color="#ffffff" variation="small" />
                      <span className={cx('file-input__upload-spinner')}>
                        {uploadingLabel}
                      </span>
                    </span>
                  )}
                  {!isUploading && buttonLabel}
                </Button>
                <Caption
                  className={cx('file-input__caption')}
                  data-qa="file-attachment__caption"
                  role={errorMessage ? 'alert' : undefined}
                >
                  {errorMessage || (
                    <div>
                      <div>
                        {t(
                          'components-attachmentFileInput-maximum_size',
                          'Maximum {{maxFileSizeInMb}}MB file size',
                          {
                            maxFileSizeInMb,
                          }
                        )}
                      </div>
                      <div>
                        <a
                          href={FILE_TYPES_ARTICLE}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {t(
                            'components-attachmentFileInput-accepted_files',
                            'View the full list of accepted files'
                          )}
                        </a>
                      </div>
                    </div>
                  )}
                </Caption>
              </div>
            );
          }}
          s3Bucket={process.env.S3_IMPORT_BUCKET}
          s3Path={s3UploadPath}
          onError={this.handleUploadError}
          onSuccess={this.handleUploadSuccess}
          onUploading={this.handleUpload}
        />
      )
    );
  }
}

export default withTranslation()(AttachmentFileInput);
