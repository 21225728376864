import React, { RefObject } from 'react';

import classNames from 'classnames/bind';
import dynamic from 'next/dynamic';
import { Loader } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';
import { useCommunityContext } from 'components/CommunityContext';
import { useRouter } from 'next/router';
import { PostListUser } from 'types/user';
import { makeMentionsUserFriendly } from 'utils/mentions';
import { Post as PostType } from 'types/post';
import { REPLY_DEPTH_QUERY } from 'schema/Replies/queries';
import { useQuery } from '@apollo/client';
import ExpandableTextArea from 'components/ExpandableTextArea';
import { useWindowSize } from 'hooks/useWindowSize';

import UserAvatar from 'components/UserAvatar';

import { BREAKPOINT_MD, COMMENT_FORM_ID } from 'utils/constants';

import styles from '../Post.module.scss';

const cx = classNames.bind(styles);

interface CommentSectionProps {
  post: PostType;
  communityWrapperRef?: RefObject<HTMLElement> | Record<string, never>; // These types need to be cleaned up
  lastCommentRef?: RefObject<HTMLElement> | Record<string, never>;
  postRef: RefObject<HTMLElement>;
  onPostChange?: () => void;
  onCommentSubmitted?: () => void;
  author?: PostListUser | null;
  additionalClassName: string;
  numOfNestedReplies?: number;
  numOfReplies?: number;
}

let CommentListSingular;
let AddCommentModal;
let CommentList;
let AddCommentContainer;
let MentionsTextArea;

const CommentSection: React.FC<CommentSectionProps> = ({
  post,
  communityWrapperRef,
  lastCommentRef,
  postRef,
  onPostChange,
  onCommentSubmitted,
  author,
  additionalClassName,
  numOfNestedReplies,
  numOfReplies,
}) => {
  const { t } = useTranslation();
  const { community, currentUser } = useCommunityContext();
  const { width } = useWindowSize();

  const router = useRouter();
  let commentId = router && router.query && (router.query.comment as string);
  let replyId;

  const { data, loading } = useQuery(REPLY_DEPTH_QUERY, {
    variables: {
      communityId: community.id,
      id: commentId,
    },
    skip: !commentId,
  });

  const reply = data?.community?.reply;
  if (reply?.depth === 2) {
    // We have been linked to a reply!
    commentId = reply?.parentPost?.id;
    replyId = reply?.id;
  }

  if (commentId) {
    if (!CommentListSingular) {
      CommentListSingular = dynamic(
        () => import('components/CommentListSingular'),
        {
          loading: () => (
            <Loader accessibilityLabel={t('common-loading', 'Loading')} />
          ),
        }
      );
    }
  } else if (!CommentList) {
    CommentList = dynamic(() => import('components/CommentList'), {
      loading: () => (
        <Loader accessibilityLabel={t('common-loading', 'Loading')} />
      ),
    });
  }

  if (!AddCommentModal) {
    AddCommentModal = dynamic(() => import('components/AddCommentModal'), {
      loading: () => (
        <Loader accessibilityLabel={t('common-loading', 'Loading')} />
      ),
    });
  }

  if (!AddCommentContainer) {
    AddCommentContainer = dynamic(
      () => import('components/AddCommentContainer'),
      {
        loading: () => (
          <Loader accessibilityLabel={t('common-loading', 'Loading')} />
        ),
      }
    );
  }

  if (!MentionsTextArea) {
    MentionsTextArea = dynamic(
      () => import('components/Mentions/MentionsTextArea'),
      {
        loading: () => (
          <Loader accessibilityLabel={t('common-loading', 'Loading')} />
        ),
      }
    );
  }

  let commentList;
  if (commentId) {
    commentList = (
      <CommentListSingular
        commentId={commentId}
        communityWrapperRef={communityWrapperRef}
        lastCommentRef={lastCommentRef}
        parentPostId={post.id}
        parentPostRef={postRef}
        replyId={replyId}
        t={t}
      />
    );
  } else {
    commentList = (
      <CommentList
        communityWrapperRef={communityWrapperRef}
        lastCommentRef={lastCommentRef}
        numOfNestedReplies={numOfNestedReplies}
        numOfReplies={numOfReplies}
        parentPostId={post.id}
        parentPostRef={postRef}
        t={t}
        onPostChange={onPostChange}
      />
    );
  }

  const relevantMentions =
    author?.id !== currentUser.id
      ? [
          {
            id: author?.id,
            avatarUrl: author?.avatarUrl,
            display: author?.fullName,
          },
        ]
      : [];

  const renderButtonForMobile = (openCommentModal, value) => {
    const displayValue = makeMentionsUserFriendly(value);

    const placeholderValue = t(
      'Type @ to mention another member in your comment'
    );

    return (
      <button
        className={cx('post-comment', { 'has-value': !!value })}
        type="button"
        onClick={openCommentModal}
      >
        {
          // Makes mentions in the placeholder text on the input that triggers the modal open more friendly.
        }
        {displayValue || placeholderValue}
      </button>
    );
  };

  const getCommentBox = (inputProps) => {
    if (width > BREAKPOINT_MD) {
      return (
        <ExpandableTextArea
          id={COMMENT_FORM_ID}
          label={t('components-post-add_comment', 'Add comment...')}
          labelClass="sr-only"
          mentionsComponent={
            <MentionsTextArea
              additionalClassName={additionalClassName}
              id={COMMENT_FORM_ID}
              isModal={inputProps.isModal}
              isPostForm={inputProps.isPostForm}
              placeholder={t(
                'Type @ to mention another member in your comment'
              )}
              relevantMentions={relevantMentions}
              textAreaClassName="mentions__text-area"
              value={inputProps.value}
              onChange={inputProps.handleMentionsChange}
            />
          }
          parentPostRef={postRef}
          s3UploadPath={community.s3UploadPath}
          isAttachmentButtonsEnabled
          isAttachmentRenderedInPortal
          {...inputProps}
        />
      );
    }

    return (
      <AddCommentModal
        additionalClassName="add-comment__modal"
        relevantMentions={relevantMentions}
        {...inputProps}
        renderButton={renderButtonForMobile}
      />
    );
  };

  const commentInput = (inputProps, user) => {
    return (
      <div className={cx('comment-input__wrapper')}>
        <UserAvatar
          avatarUrl={user.avatarUrl}
          className={cx('comment-input__avatar-container')}
          size="sm"
          srcSet={`${user.avatarUrl}?width=40 1x, ${user.avatarUrl}?width=40&dpr=2 2x, ${user.avatarUrl}?width=40&dpr=3 3x`}
          userId={user.id}
          username={user.fullName}
          shouldOpenProfile
        />

        {getCommentBox(inputProps)}
      </div>
    );
  };

  if (loading) {
    return <Loader accessibilityLabel={t('common-loading', 'Loading')} />;
  }

  return (
    <div className={cx('comment-container')} data-qa="comment-container">
      {commentList}
      <AddCommentContainer
        communityWrapperRef={communityWrapperRef}
        lastCommentRef={lastCommentRef}
        parentPostId={post.id}
        renderChildren={commentInput}
        t={t}
        onCommentSubmitted={onCommentSubmitted}
      />
    </div>
  );
};

export default CommentSection;
