import { gql } from '@apollo/client';
import {
  POST_FRAGMENT,
  REPLY_FRAGMENT,
  REPLY_EDGE_FRAGMENT,
} from 'utils/queries';

const REPLY_QUERY = gql`
  query Reply(
    $communityId: ID!
    $postId: ID!
    $id: ID!
    $startCursor: String
    $numOfNestedReplies: Int
    $numberOfReactions: Int!
  ) {
    community(id: $communityId) {
      id
      post(id: $postId) {
        ...PostFields
      }
      reply(id: $id) {
        ...ReplyFields
        replies(
          orderBy: { field: CREATED_AT, direction: ASC }
          before: $startCursor
          last: $numOfNestedReplies
        ) @connection(key: "replies") {
          edges {
            cursor
            node {
              ...ReplyFields
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${REPLY_FRAGMENT}
  ${POST_FRAGMENT}
`;

const REPLIES_QUERY = gql`
  query Replies(
    $communityId: ID!
    $postId: ID!
    $numOfReplies: Int
    $numOfNestedReplies: Int
    $startCursor: String
    $numberOfReactions: Int!
  ) {
    community(id: $communityId) {
      id
      post(id: $postId) {
        id
        replies(
          last: $numOfReplies
          before: $startCursor
          orderBy: { field: CREATED_AT, direction: ASC }
        ) @connection(key: "replies") {
          edges {
            cursor
            node {
              ...ReplyEdgeFields
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${REPLY_EDGE_FRAGMENT}
`;

const NESTED_REPLIES_QUERY = gql`
  query NestedReplies(
    $communityId: ID!
    $parentReplyId: ID!
    $numOfReplies: Int
    $startCursor: String
    $numberOfReactions: Int!
  ) {
    community(id: $communityId) {
      id
      reply(id: $parentReplyId) {
        id
        replies(
          last: $numOfReplies
          before: $startCursor
          orderBy: { field: CREATED_AT, direction: ASC }
        ) @connection(key: "replies") {
          edges {
            cursor
            node {
              ...ReplyFields
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          totalCount
        }
      }
    }
  }
  ${REPLY_FRAGMENT}
`;

const REPLY_DEPTH_QUERY = gql`
  query ReplyDepth($communityId: ID!, $id: ID!) {
    community(id: $communityId) {
      id
      reply(id: $id) {
        id
        depth
        parentPost {
          id
        }
      }
    }
  }
`;

export { REPLIES_QUERY, REPLY_QUERY, NESTED_REPLIES_QUERY, REPLY_DEPTH_QUERY };
