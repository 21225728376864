import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as filestack from 'filestack-js';

class SimpleFilePicker extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    isUploading: false,
    totalPercent: 0,
  };

  constructor(props) {
    super(props);

    this.filestackClient = filestack.init(props.filestackAPIKey);
  }

  openPicker = () => {
    this.fileInput.click();
  };

  onProgress = (event) => {
    this.setState({ totalPercent: event.totalPercent });
  };

  handleFileChange = (event) => {
    const {
      accept,
      awsRegion,
      clientOptions,
      maxFileSizeInMb,
      s3Path,
      s3Bucket,
      onError,
      onSuccess,
      onUploading,
    } = this.props;
    const maxFileSizeInBytes = maxFileSizeInMb * 1024 * 1024;

    const uploadOptions = {
      onProgress: this.onProgress,
    };
    const storageOptions = {
      access: 'public',
      container: s3Bucket,
      location: 's3',
      path: s3Path,
      region: awsRegion,
    };
    const file = event.target.files[0];

    if (file.size > maxFileSizeInBytes) {
      return onError(
        `File is too large. Maximum file size is ${maxFileSizeInMb}MB.`
      );
    }

    // This will turn the accept prop into a regular expression, e.g.
    // "*/*" becomes ".*/.*"
    // "image/*" becomes "image/.*"
    // "image/png,image/jpeg,application/*" becomes "image/png|image/jpeg|application/.*"
    const fileTypeRegex = accept.replace(/,/g, '|').replace('*', '.*');
    if (!file.type.match(fileTypeRegex)) {
      return onError(
        `File type ${
          file.type
        } is not accepted. Must be one of the following: ${accept.replace(
          /,/g,
          ', '
        )}`
      );
    }

    this.setState({ isUploading: true }, () => {
      onUploading();
    });

    return this.filestackClient
      .upload(file, uploadOptions, storageOptions, null, clientOptions)
      .then((uploadedFile) => {
        return onSuccess(uploadedFile);
      })
      .catch((failedFile) => {
        this.setState({ isUploading: false });
        return onError(`Failed to upload ${failedFile.name}.`);
      });
  };

  render() {
    const { isUploading, totalPercent } = this.state;
    const { accept, render, ariaControls } = this.props;

    return (
      <>
        <input
          accept={accept}
          aria-controls={ariaControls}
          data-qa="simple-file-picker__input"
          ref={(ref) => {
            this.fileInput = ref;
          }}
          style={{ display: 'none' }}
          type="file"
          onChange={this.handleFileChange}
        />
        {render({ openPicker: this.openPicker, isUploading, totalPercent })}
      </>
    );
  }
}

SimpleFilePicker.propTypes = {
  /** File types to accept, e.g. "image/*" */
  accept: PropTypes.string,
  ariaControls: PropTypes.string,
  awsRegion: PropTypes.string.isRequired,
  clientOptions: PropTypes.object.isRequired,
  filestackAPIKey: PropTypes.string.isRequired,
  maxFileSizeInMb: PropTypes.number,
  /** Render prop used to render the upload button, will be passed an
   *  openPicker function, isUploading & totalPercent */
  render: PropTypes.func.isRequired,
  s3Bucket: PropTypes.string.isRequired,
  s3Path: PropTypes.string.isRequired,
  /** Function called when a file failed to be uploaded, will be passed an errorMessage string */
  onError: PropTypes.func,
  /** Function called when a file has successfully been uploaded, will be passed
   *  an uploadedFile object */
  onSuccess: PropTypes.func.isRequired,
  onUploading: PropTypes.func,
};

SimpleFilePicker.defaultProps = {
  accept: '*/*',
  ariaControls: null,
  maxFileSizeInMb: 5,
  onError: () => {},
  onUploading: () => {},
};

export default SimpleFilePicker;
