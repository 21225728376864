import { TFunction } from 'next-i18next';

export const getScreenReaderText = (
  hasReacted: boolean,
  reactionCount: number,
  isReactingTo: string,
  t: TFunction
): string => {
  switch (isReactingTo) {
    case 'comment':
      return hasReacted
        ? `${t(
            'components-reactionButton-remove_heart_from_comment_sr_text',
            'You reacted with a heart to this comment. Click to remove the reaction.'
          )} ${getTotalNumberOfReactionsString(reactionCount, t)}`
        : `${t(
            'components-reactionButton-react_with_heart_to_comment_sr_text',
            'You have not reacted to this comment. Click to react with a heart.'
          )} ${getTotalNumberOfReactionsString(reactionCount, t)}`;
    default:
      return hasReacted
        ? `${t(
            'components-reactionButton-remove_heart_from_post_sr_text',
            'You reacted with a heart to this post. Click to remove the reaction.'
          )} ${getTotalNumberOfReactionsString(reactionCount, t)}`
        : `${t(
            'components-reactionButton-react_with_heart_to_post_sr_text',
            'You have not reacted to this post. Click to react with a heart.'
          )} ${getTotalNumberOfReactionsString(reactionCount, t)}`;
  }
};

function getTotalNumberOfReactionsString(
  reactionCount: number,
  t: TFunction
): string {
  return t(
    'components-reactionButton-total_reactions_sr_text',
    'The total number of reactions for this item is {{reactionCount}}.',
    {
      reactionCount,
    }
  );
}
