import urlParser from 'js-video-url-parser/lib/base';
// eslint-disable-next-line import/no-duplicates
import 'js-video-url-parser/lib/provider/loom';
import {
  LoomParseResult,
  // eslint-disable-next-line import/no-duplicates
} from 'js-video-url-parser/lib/provider/loom';

const isLoomUrl = (url: string): boolean => {
  const parseResult = urlParser.parse(url) as LoomParseResult;
  return parseResult !== undefined && parseResult.provider === 'loom';
};

const parseLoomId = (url: string): string => {
  return (urlParser.parse(url) as LoomParseResult)?.id || '';
};

export { isLoomUrl, parseLoomId };
