import React, { useCallback, useEffect, useState } from 'react';
import Button from 'koba/components/Button';
import EmojiPicker from 'components/EmojiPicker';
import AttachmentButtons from 'components/AttachmentButtons';
import { useWindowSize } from 'hooks/useWindowSize';
import { TFunction } from 'next-i18next';

import classNames from 'classnames/bind';
import { isValidText } from 'utils/textHelpers';
import { insertEmoji } from 'utils/mentions';

import Label from 'koba/components/Label';
import { BREAKPOINT_MD } from 'utils/constants';
import { Attachment } from 'schema/Post/types';
import styles from './ExpandableTextArea.module.scss';

const cx = classNames.bind(styles);

interface ExpandableTextAreaProps {
  attachment?: Attachment;
  attachmentAltText?: string | null;
  cancelComment?: () => void;
  color: string;
  handleAttachmentAltTextChange?: (text: string) => void;
  handleAttachmentChange?: (attachment: Attachment) => void;
  handleBlur?: () => void;
  handleInputChange: (value: string) => void;
  id: string;
  inputRef?: React.MutableRefObject<HTMLTextAreaElement | HTMLInputElement>;

  isAlwaysExpanded?: boolean;
  isAttachmentButtonsEnabled?: boolean;
  isAttachmentRenderedInPortal?: boolean;
  isEditing?: boolean;
  isModal?: boolean;
  isReply?: boolean;
  isSubmitDisabled?: boolean;
  label?: string;
  labelClass?: string;
  mentionsComponent: React.ReactElement;
  parentPostRef?: React.MutableRefObject<HTMLElement | undefined>;
  s3UploadPath?: string;
  submitComment?: () => void;
  t: TFunction;
  value: string;
}

const ExpandableTextArea: React.FC<ExpandableTextAreaProps> = ({
  attachment,
  attachmentAltText,
  cancelComment,
  color,
  handleAttachmentAltTextChange,
  handleAttachmentChange,
  handleBlur,
  handleInputChange,
  id,
  mentionsComponent,
  inputRef: parentPassedRef,
  isAlwaysExpanded,
  isAttachmentButtonsEnabled,
  isAttachmentRenderedInPortal,
  isEditing,
  isModal,
  isReply,
  isSubmitDisabled,
  label,
  labelClass,
  parentPostRef,
  s3UploadPath = '',
  submitComment,
  t,
  value,
}) => {
  const cancelButtonRef = React.useRef(null);
  const postButtonRef = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement>();
  const containerClassName = 'expandable-text__container';
  const { width } = useWindowSize();
  const isDesktop = width >= BREAKPOINT_MD;

  const [recomputePortal, setRecomputePortal] = useState(false);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(true);
  const [isAttachmentUploading, setIsAttachmentUploading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isAlwaysExpanded);

  const expandedClassName = isExpanded ? `${containerClassName}--expanded` : '';

  const blurExpandableTextarea = (cancelRef, buttonRef, parentRef) => {
    cancelRef.blur();
    buttonRef.blur();
    if (!isEditing) {
      parentRef.focus();
      blurTextarea();
      // TODO: fix this ridiculous hack to close the FileAttachmentInput after submitting a comment
      setIsAttachmentOpen(false);
      setIsAttachmentOpen(true);
    }
  };

  const getInputRef = ():
    | HTMLInputElement
    | HTMLTextAreaElement
    | undefined
    | null => (parentPassedRef ? parentPassedRef.current : inputRef.current);

  useEffect(() => {
    // re-calculate the portal element after the component is mounted
    if (isAttachmentRenderedInPortal) {
      setRecomputePortal(true);
    }
  }, []);

  const handleCancelComment = (
    cancelButton,
    postButton,
    parentPost
  ) => async () => {
    const cancelRef = cancelButton.current;
    const postRef = postButton.current;
    const parentRef = parentPost.current;
    if (cancelComment) await cancelComment();
    blurExpandableTextarea(cancelRef, postRef, parentRef);
  };

  const handleEmojiPick = (emoji) => {
    const inputElement = getInputRef();
    if (inputElement) {
      const { selectionStart, value: inputValue } = inputElement;
      const updatedContent = insertEmoji(
        emoji,
        selectionStart,
        inputValue,
        value
      );
      handleInputChange(updatedContent);
      inputElement.focus();
    }
  };

  const handleSubmitComment = (
    cancelButton,
    postButton,
    parentPost
  ) => async () => {
    const cancelElement = cancelButton.current;
    const postElement = postButton.current;
    const parentElement = parentPost.current;
    focusTextarea();
    if (submitComment) await submitComment();
    blurExpandableTextarea(cancelElement, postElement, parentElement);
    if (handleBlur) handleBlur();
  };

  const primaryBtnText = isReply ? 'Post reply' : 'Post comment';

  const handleOnBlur = (e) => {
    if (!isReply) {
      return;
    }
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        const ref = getInputRef();
        if (ref) {
          const val = getInputRef()?.value;
          if (!val) {
            if (handleBlur) handleBlur();
          }
        }
      }
    }, 0);
  };

  const onAttachmentAltTextChange = useCallback(
    (altText) => {
      if (handleAttachmentAltTextChange) handleAttachmentAltTextChange(altText);
    },
    [attachment]
  );

  const onAttachmentChange = useCallback(
    (newAttachment: Attachment) => {
      setIsExpanded(newAttachment !== null);

      setIsAttachmentUploading(false);
      if (handleAttachmentChange) handleAttachmentChange(newAttachment);
    },
    [attachment, recomputePortal]
  );

  const onAttachmentUploading = useCallback(() => {
    setIsAttachmentUploading(true);
    focusTextarea();
  }, [attachment, recomputePortal]);

  const onFilePickerOpen = useCallback(
    (isExpandedLocal) => {
      setIsExpanded(isExpandedLocal); // when the file picker is open, keep the textarea expanded
    },
    [attachment, recomputePortal]
  );

  const executeMethodsOnTextarea = ({ method = 'focus' } = {}) => {
    if (parentPassedRef && parentPassedRef.current) {
      // @ts-ignore
      let { input } = parentPassedRef.current;
      input = parentPassedRef.current;
      input[method]();
    } else if (inputRef && inputRef.current) {
      // @ts-ignore
      let { input } = inputRef.current;
      input = inputRef.current;
      input[method]();
    }
  };

  const focusTextarea = () => {
    executeMethodsOnTextarea();
  };

  const blurTextarea = () => {
    executeMethodsOnTextarea({ method: 'blur' });
  };

  const onFocusTextarea = useCallback(() => focusTextarea(), [attachment]);

  const clonedMentionsArea = React.cloneElement(mentionsComponent, {
    forwardRef: parentPassedRef || inputRef,
    isExpanded,
  });

  return (
    <div
      className={cx('full-width', {
        'mentions-enabled-post-detail': true && !isModal,
      })}
      onBlur={handleOnBlur}
    >
      {label && (
        <Label
          className={cx('expandable-text__label', labelClass)}
          htmlFor={id}
        >
          {label}
        </Label>
      )}
      <div className={cx(containerClassName, expandedClassName)} tabIndex={-1}>
        {clonedMentionsArea}
        {isDesktop && (
          <div className={cx('actions')}>
            <div className="attachment-input__portal" />
            <div className={cx('actions__wrapper')}>
              <div className={cx('actions__wrapper__left-container')}>
                <EmojiPicker
                  t={t}
                  onDismiss={focusTextarea}
                  onPick={handleEmojiPick}
                />

                {isAttachmentButtonsEnabled && isAttachmentOpen && (
                  <AttachmentButtons
                    altText={attachmentAltText}
                    attachment={attachment}
                    inputRef={parentPassedRef || inputRef}
                    isAttachmentRenderedInPortal={isAttachmentRenderedInPortal}
                    s3UploadPath={s3UploadPath}
                    isIconOnly
                    onAltTextChange={onAttachmentAltTextChange}
                    onAttachmentClicked={onFocusTextarea}
                    onChange={onAttachmentChange}
                    onFilePickerOpen={onFilePickerOpen}
                    onUploading={onAttachmentUploading}
                  />
                )}
              </div>

              {parentPostRef && (
                <div>
                  {/* @ts-ignore - Can remove this once we convert the Button component */}
                  <Button
                    appearance="knockout"
                    className={cx('actions__cancel')}
                    ref={cancelButtonRef}
                    size="small"
                    onClick={handleCancelComment(
                      cancelButtonRef,
                      postButtonRef,
                      parentPostRef
                    )}
                  >
                    {t('common-cancel', 'Cancel')}
                  </Button>
                  {/* @ts-ignore - Can remove this once we convert the Button component */}
                  <Button
                    appearance="default"
                    color={color}
                    dataQA="save-changes__btn"
                    isDisabled={
                      (!attachment && !isValidText(value)) ||
                      isSubmitDisabled ||
                      isAttachmentUploading
                    }
                    ref={postButtonRef}
                    size="small"
                    onClick={handleSubmitComment(
                      cancelButtonRef,
                      postButtonRef,
                      parentPostRef
                    )}
                  >
                    {t(isEditing ? 'Save changes' : primaryBtnText)}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandableTextArea;
