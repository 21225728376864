import React, { ReactNode, HTMLAttributes } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Label.module.scss';

const cx = classNames.bind(styles);

interface LabelProps extends HTMLAttributes<HTMLLabelElement> {
  className?: string;
  children: ReactNode;
  htmlFor?: string;
}
const Label: React.FC<LabelProps> = ({
  className,
  children,
  htmlFor,
  ...attrs
}) => {
  return (
    <label className={cx('label', className)} htmlFor={htmlFor} {...attrs}>
      {children}
    </label>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};

Label.defaultProps = {
  className: '',
  htmlFor: '',
};

export default Label;
