import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import {
  useMutation,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';

import Icon from 'koba/components/Icon';
import { goToPostPage } from 'utils/routerHelpers';

import {
  BREAKPOINT_MD,
  POSTS_PER_PAGE,
  NUMBER_OF_REACTIONS,
  FEED_REPLY_COUNT,
  FEED_COMMENT_COUNT,
} from 'utils/constants';
import TrackingEvent from 'constants/trackingEvents';

import { POST_LIST_QUERY } from 'schema/Post/queries';
import { DANGER_TYPE } from 'koba/components/InlineActions/constants';
import { useCommunityContext } from 'components/CommunityContext';
import { isModerator } from 'utils/userHelpers';
import { relativeFormattedDateTime } from 'helpers/dateHelpers';
import {
  FOLLOW_POST_MUTATION,
  UNFOLLOW_POST_MUTATION,
  PIN_POST_MUTATION,
  UNPIN_POST_MUTATION,
} from 'schema/Post/mutations';
import { useWindowSize } from 'hooks/useWindowSize';

import { TFunction } from 'next-i18next';
import {
  MentionedUsersFieldFragment,
  PostListQuery,
} from '__generated__/graphql/legacy/graphql';
import { PostListUser } from 'types/user';
import { Post as PostType, PostAction } from 'types/post';

import { features } from 'constants/featureFlags';
import PostHeader from './components/PostHeader';
import PostFooter from './components/PostFooter';
import PostContent from './components/PostContent';
import CommentSection from './components/CommentSection';

import styles from './Post.module.scss';

const cx = classNames.bind(styles);
interface PostProps {
  content: string;
  mentionedUsers?: MentionedUsersFieldFragment | null;
  post: PostType;
  postReactionCount: number;
  refetchPostList?: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<PostListQuery>>;
  shouldShowTagOnPost?: boolean;
  t: TFunction;
  time: string;
  totalCommentCount: number;
  author?: PostListUser | null;
  onPostChange?(): void;
  handleDeletePost(event): void;
  handleEditPost(event): void;
  trackPostEvent(event): void;
}

const Post: React.FC<PostProps> = ({
  content,
  mentionedUsers,
  author,
  post,
  time,
  t,
  refetchPostList,
  totalCommentCount,
  postReactionCount,
  shouldShowTagOnPost = false,
  onPostChange,
  handleDeletePost,
  handleEditPost,
  trackPostEvent,
}) => {
  const router = useRouter();
  const { community, currentUser, featureFlags } = useCommunityContext();
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINT_MD;
  const [shouldClampContent, setShouldClampContent] = useState<boolean>(
    (content?.length || 0) > 480
  );
  const commentsInFeedEnabled = featureFlags[features.COMMENTS_IN_FEED];

  const firstAttachment = post?.postAttachments?.[0];

  const refetchQueries = [
    {
      query: POST_LIST_QUERY,
      variables: {
        communityId: community.id,
        numOfPosts: POSTS_PER_PAGE,
        filter: {
          following: true,
        },
        postOrderField: undefined,
        numberOfReactions: NUMBER_OF_REACTIONS,
      },
    },
  ];

  const [unfollowPost, { loading: unfollowLoading }] = useMutation(
    UNFOLLOW_POST_MUTATION,
    {
      refetchQueries,
    }
  );
  const [followPost, { loading: followLoading }] = useMutation(
    FOLLOW_POST_MUTATION,
    {
      refetchQueries,
    }
  );
  const [pinPost, { loading: pinLoading }] = useMutation(PIN_POST_MUTATION);
  const [unpinPost, { loading: unpinLoading }] = useMutation(
    UNPIN_POST_MUTATION
  );

  const goToPost = () => {
    goToPostPage(router, community.id, post.id, true, true);
  };

  const currentUserIsModerator = isModerator(currentUser.roles);
  const userCanEditPost =
    currentUserIsModerator || (currentUser.id && currentUser.id === author?.id);

  const postRef = React.useRef<HTMLDivElement>(null);
  const creationDateTime = relativeFormattedDateTime(
    time,
    currentUser?.locale,
    t
  );

  const postActionsList: PostAction[] = [];

  if (post.isFollowing && !unfollowLoading) {
    postActionsList.push({
      name: 'unfollow',
      text: `${t('Unfollow')}`,
      onClick: () => {
        unfollowPost({
          variables: { id: post.id, numberOfReactions: NUMBER_OF_REACTIONS },
        }).then(() => {
          onPostChange?.();
          trackPostEvent(TrackingEvent.UNFOLLOW);
        });
      },
    });
  } else if (!followLoading) {
    postActionsList.push({
      name: 'follow',
      text: t('Follow'),
      onClick: () => {
        followPost({
          variables: { id: post.id, numberOfReactions: NUMBER_OF_REACTIONS },
        }).then(() => {
          onPostChange?.();
          trackPostEvent(TrackingEvent.FOLLOW);
        });
      },
    });
  }

  if (userCanEditPost) {
    postActionsList.push({
      name: 'edit',
      text: t('common-edit', 'Edit'),
      onClick: handleEditPost,
    });
  }

  if (currentUserIsModerator) {
    if (post.pinnedAt && !unpinLoading) {
      postActionsList.push({
        name: 'unpin',
        text: t('Unpin'),
        onClick: () =>
          unpinPost({
            variables: { id: post.id, numberOfReactions: NUMBER_OF_REACTIONS },
          }).then(() => {
            onPostChange?.();
            trackPostEvent(TrackingEvent.UNPIN);
            refetchPostList?.({ shouldShowLoading: true });
          }),
      });
    } else if (!pinLoading) {
      postActionsList.push({
        name: 'pin',
        text: t('Pin'),
        onClick: () =>
          pinPost({
            variables: { id: post.id, numberOfReactions: NUMBER_OF_REACTIONS },
          }).then(() => {
            onPostChange?.();
            trackPostEvent(TrackingEvent.PIN);
            refetchPostList?.({ shouldShowLoading: true });
          }),
      });
    }

    postActionsList.push({
      name: 'delete',
      text: (
        <div className={cx('post__detail-action')}>
          <Icon className={cx('post__detail-action__icon')} name="trash-can" />
          <span>{t('Delete post')}</span>
        </div>
      ),
      title: t('Delete post'),
      onClick: handleDeletePost,
      type: DANGER_TYPE,
    });
  }

  const hasAttachment = post.postAttachments && post.postAttachments[0];

  return (
    <div className={cx('post')}>
      <div
        aria-label={`${t('Read full post')}: ${post.title}`}
        className={cx('post__container')}
        ref={postRef}
        role="button"
        tabIndex={0}
      >
        <div
          className={cx('post__detail', {
            'is-spaces-enabled': shouldShowTagOnPost, // we should try and avoid coupling feature names to classes
          })}
          id={post.id}
          role="article"
        >
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={cx('post__body', {
              'is-spaces-enabled': shouldShowTagOnPost,
            })}
            onClick={goToPost}
            onKeyPress={(e) => {
              if (e.key !== 'Enter' && e.key !== ' ') {
                goToPost();
              }
            }}
          >
            <PostHeader
              author={author}
              creationDateTime={creationDateTime || ''}
              editedAt={post.editedAt}
              isFollowing={post.isFollowing}
              isMobile={isMobile}
              pinnedAt={post.pinnedAt}
              postActionsList={postActionsList}
              title={post.title || ''}
              showFollowingTag
            />

            <PostContent
              content={content}
              firstAttachment={firstAttachment}
              hasAttachment={!!hasAttachment}
              isPostPage={false}
              mentionedUsers={mentionedUsers}
              setShouldClampContent={(shouldClamp) => {
                setShouldClampContent(shouldClamp);
              }}
              shouldClampContent={shouldClampContent}
              t={t}
              shouldScroll
            />
          </div>

          <PostFooter
            communityId={community.id}
            goToPost={goToPost}
            hasReacted={post.hasReacted}
            post={post}
            postReactionCount={postReactionCount}
            showSpaceTag={shouldShowTagOnPost}
            spaceIconName={post.communitySpace?.iconName || ''}
            spaceId={post.communitySpace?.id || ''}
            spaceName={post.communitySpace?.name || ''}
            totalCommentCount={totalCommentCount}
          />

          {commentsInFeedEnabled && (
            <CommentSection
              additionalClassName="post__feed"
              author={author}
              numOfNestedReplies={FEED_REPLY_COUNT}
              numOfReplies={FEED_COMMENT_COUNT}
              post={post}
              postRef={postRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Post;
