import React from 'react';
import type { TFunction } from 'next-i18next';

interface FileSizeLabelProps extends React.HTMLProps<HTMLSpanElement> {
  byteCount: number;
  t: TFunction;
}

export const FileSizeLabel: React.FC<FileSizeLabelProps> = ({
  byteCount,
  t,
  ...props
}) => {
  const shouldShowMB = byteCount > 1_000_000;
  let label: string;
  if (shouldShowMB) {
    const size = Math.round(byteCount / 1_000_000);
    label = t('({{size}} MB)', { size });
  } else {
    const size = Math.round(byteCount / 1000);
    label = t('({{size}} KB)', { size });
  }
  return <span {...props}>{label}</span>;
};
