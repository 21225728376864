import { gql } from '@apollo/client';
import { REPLY_FRAGMENT } from '../../utils/queries';

export const CREATE_REPLY_MUTATION = gql`
  mutation CreateReply(
    $discussionableId: ID!
    $content: String
    $parentPostId: ID
    $parentReplyId: ID
    $postAttachmentInput: PostAttachmentInput
    $mentionedUserIds: [ID!]
    $numberOfReactions: Int!
  ) {
    createReply(
      input: {
        discussionableId: $discussionableId
        content: $content
        parentPostId: $parentPostId
        parentReplyId: $parentReplyId
        postAttachmentInput: $postAttachmentInput
        mentionedUserIds: $mentionedUserIds
      }
    ) {
      userErrors {
        code
        message
      }
      reply {
        ...ReplyFields
      }
    }
  }
  ${REPLY_FRAGMENT}
`;

export const EDIT_REPLY_MUTATION = gql`
  mutation EditReply($input: EditReplyInput!, $numberOfReactions: Int!) {
    editReply(input: $input) {
      userErrors {
        code
        message
      }
      reply {
        ...ReplyFields
      }
    }
  }
  ${REPLY_FRAGMENT}
`;

export const DELETE_REPLY_MUTATION = gql`
  mutation DeleteReply($id: ID!) {
    deleteReply(input: { id: $id }) {
      userErrors {
        code
        message
      }
    }
  }
`;

export const TOGGLE_REACTION_ON_REPLY_MUTATION = gql`
  mutation ToggleReactionOnReply(
    $id: ID!
    $reactionType: PostReactionType!
    $numberOfReactions: Int!
  ) {
    toggleReactionOnReply(input: { id: $id, reactionType: $reactionType }) {
      userErrors {
        code
        message
      }
      reply {
        ...ReplyFields
      }
    }
  }
  ${REPLY_FRAGMENT}
`;

export const FOLLOW_REPLY_MUTATION = gql`
  mutation FollowReply($id: ID!, $numberOfReactions: Int!) {
    followReply(input: { id: $id }) {
      userErrors {
        code
        message
      }
      reply {
        ...ReplyFields
      }
    }
  }
  ${REPLY_FRAGMENT}
`;

export const UNFOLLOW_REPLY_MUTATION = gql`
  mutation UnfollowReply($id: ID!, $numberOfReactions: Int!) {
    unfollowReply(input: { id: $id }) {
      userErrors {
        code
        message
      }
      reply {
        ...ReplyFields
      }
    }
  }
  ${REPLY_FRAGMENT}
`;
