import { REACTION_ICONS, REACTION_TYPES } from 'utils/constants';
import { Post } from 'types/post';
import { ReplyEdgeFieldsFragment as Comment } from '__generated__/graphql/legacy/graphql';

export function getIconName(
  reactionType = REACTION_TYPES.LIKE_POST,
  hasReacted = false
): string {
  switch (reactionType) {
    case REACTION_TYPES.LIKE_POST:
      return hasReacted
        ? REACTION_ICONS.LIKE_POST_TRUE
        : REACTION_ICONS.LIKE_POST_FALSE;
    default:
      return REACTION_ICONS.LIKE_POST_FALSE;
  }
}

export const getReactors = (
  post: Post | Comment
): Array<{ fullName: string; id: string }> => {
  const edges = post && post.postReactions && post.postReactions.edges;
  const usersWithNames = edges?.filter((edge) => {
    const user = edge?.node?.user;
    return user?.id != null && user?.fullName != null;
  });

  return Array.isArray(usersWithNames)
    ? usersWithNames.map((edge) => {
        const user = edge?.node?.user;
        return { id: user?.id || '', fullName: user?.fullName || '' };
      })
    : [];
};
