import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import Icon from 'koba/components/Icon';
import { useMutation } from '@apollo/client';
import { ButtonWithIconLeft } from 'koba/components/Button';
import { rawId } from 'utils/rawId';
import { TOGGLE_REACTION_ON_REPLY_MUTATION } from 'schema/Replies/mutations';
import { TOGGLE_REACTION_ON_POST_MUTATION } from 'schema/Post/mutations';
import { genericToastAlert } from 'utils/toastHelpers';
import { EVENT_POST_TYPES, NUMBER_OF_REACTIONS } from 'utils/constants';
import { getIconName } from 'helpers/reactionHelpers';
import { TFunction } from 'next-i18next';
import { useCommunityContext } from 'components/CommunityContext';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { getScreenReaderText } from './helpers';
import ReactionHoverList from './ReactionHoverList';

import styles from './ReactionButton.module.scss';

const cx = classNames.bind(styles);

interface ReactionButtonProps {
  appearance?: 'default' | 'ghost' | 'knockout' | 'link' | 'block';
  hasReacted?: boolean;
  id: string;
  isReactingTo: 'comment' | 'post';
  parentPostId?: string;
  parentReplyId?: string;
  postReactionCount: number;
  reactionType: string;
  reactors: Array<{ fullName: string; id: string }>;
  size?: 'small' | 'x-small';
  t: TFunction;
}

const ReactionButton: React.FC<ReactionButtonProps> = ({
  appearance = 'knockout',
  hasReacted: hasReactedProp,
  id,
  isReactingTo,
  parentPostId = '',
  parentReplyId = '',
  postReactionCount,
  reactionType,
  reactors,
  size = 'small',
  t,
}) => {
  const { trackEvent } = useCommunityContext();
  const [iconName, setIconName] = useState(
    getIconName(reactionType, hasReactedProp)
  );
  const [reactionCount, setReactionCount] = useState(postReactionCount);
  const [hasReacted, setHasReacted] = useState(hasReactedProp);
  const screenReaderText = getScreenReaderText(
    !!hasReacted,
    reactionCount,
    isReactingTo,
    t
  );

  useEffect(() => {
    setIconName(getIconName(reactionType, hasReacted));
  }, [reactionType, hasReacted]);

  const [
    toggleReactionOnPostMutation,
    { loading: togglePostReactionLoading },
  ] = useMutation(TOGGLE_REACTION_ON_POST_MUTATION, {
    onError: genericToastAlert,
  });

  const [
    toggleReactionOnReplyMutation,
    { loading: toggleReplyReactionLoading },
  ] = useMutation(TOGGLE_REACTION_ON_REPLY_MUTATION, {
    onError: genericToastAlert,
  });

  const handleParentPostReactionClick = async () => {
    if (!togglePostReactionLoading) {
      const {
        data: reactPostData,
        errors: reactPostError,
      } = await toggleReactionOnPostMutation({
        variables: { id, reactionType, numberOfReactions: NUMBER_OF_REACTIONS },
      });

      const userErrors = reactPostData?.toggleReactionOnPost?.userErrors;
      if (!userErrors?.length && !reactPostError?.length) {
        const {
          hasReacted: updatedHasReacted,
        } = reactPostData.toggleReactionOnPost.post;

        trackReactEvent({
          hasReacted: updatedHasReacted,
        });
      }
    }
  };

  const handleReplyReactionClick = async () => {
    if (!toggleReplyReactionLoading) {
      const {
        data: reactReplyData,
        errors: reactReplyError,
      } = await toggleReactionOnReplyMutation({
        variables: { id, reactionType, numberOfReactions: NUMBER_OF_REACTIONS },
      });

      const userErrors = reactReplyData?.toggleReactionOnReply?.userErrors;
      if (!userErrors?.length && !reactReplyError?.length) {
        const {
          hasReacted: updatedHasReacted,
        } = reactReplyData.toggleReactionOnReply.reply;

        trackReactEvent({
          hasReacted: updatedHasReacted,
        });
      }
    }
  };

  const handleReactionClick = (e) => {
    e.stopPropagation();

    if (togglePostReactionLoading || toggleReplyReactionLoading) return;

    setReactionCount(hasReacted ? reactionCount - 1 : reactionCount + 1);
    setHasReacted(!hasReacted);

    if (!parentPostId && !parentReplyId) {
      handleParentPostReactionClick();
    } else {
      handleReplyReactionClick();
    }
  };

  const trackReactEvent = (updatedInfo) => {
    let postType = parentReplyId
      ? EVENT_POST_TYPES.REPLY
      : EVENT_POST_TYPES.COMMENT;

    postType = parentPostId ? postType : EVENT_POST_TYPES.POST;

    const reactedAt = new Date();

    trackEvent(TrackingEvent.POST_REACTED, {
      [TrackingProperty.POST_ID]: rawId(id),
      [TrackingProperty.POST_ID_ENCODED]: id,
      [TrackingProperty.POST_TYPE]: postType,
      [TrackingProperty.INTERACTION_TYPE]: updatedInfo.hasReacted
        ? 'Added Reaction'
        : 'Removed Reaction',
      [TrackingProperty.REACTION_TIMESTAMP]: reactedAt.toISOString(),
    });
  };

  const reactionButton = (
    <ButtonWithIconLeft
      appearance={appearance}
      className={parentPostId ? cx('comment__reaction-button') : ''}
      data-qa="reaction-button"
      size={size}
      onClick={handleReactionClick}
    >
      <Icon name={iconName} />
      <span aria-live="polite" className={cx('sr-only')}>
        {screenReaderText}
      </span>
      <span aria-hidden>{reactionCount}</span>
    </ButtonWithIconLeft>
  );

  const isLoading = togglePostReactionLoading || toggleReplyReactionLoading;
  if (reactors && reactionCount && !isLoading) {
    return (
      <ReactionHoverList
        hasReacted={!!hasReacted}
        postReactionCount={reactionCount}
        reactionsToShow={NUMBER_OF_REACTIONS}
        reactors={reactors}
        t={t}
      >
        {reactionButton}
      </ReactionHoverList>
    );
  }

  return reactionButton;
};

export default ReactionButton;
