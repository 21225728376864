import React, { useState } from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import { Tooltip } from '@thinkific/toga-react';
import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import styles from 'components/EmojiPicker/EmojiPicker.module.scss';
import getDictionary from 'components/EmojiPicker/EmojiPickerDictionary';
import { TFunction } from 'next-i18next';

const cx = classNames.bind(styles);

interface EmojiPickerProps {
  /** Position of the emoji picker modal in relation to the emoji button * */
  position?: 'right' | 'left';
  t: TFunction;
  onDismiss?(): void;
  onPick(emoji: string): void;
}

const EmojiPicker: React.FC<EmojiPickerProps> = ({
  onDismiss,
  onPick,
  position = 'right',
  t,
}) => {
  const [isPickerDisplayed, setIsPickerDisplayed] = useState(false);

  const handleSelect = (emoji) => {
    const { native } = emoji;
    // eslint-disable-next-line no-unused-expressions
    native && onPick(native);
    setIsPickerDisplayed(false);
  };

  const handleDismiss = () => {
    setIsPickerDisplayed(false);
    onDismiss?.();
  };

  return (
    <div className={cx('emoji-picker')} data-qa="emoji-picker">
      {isPickerDisplayed && (
        <div
          className={cx(
            'emoji-picker__emoji-mart',
            `emoji-picker__emoji-mart--${position}`
          )}
        >
          <div
            aria-label={t('Close emoji picker')}
            className={cx('emoji-picker__overlay')}
            role="button"
            tabIndex={0}
            onClick={handleDismiss}
            onKeyPress={handleDismiss}
          />
          <Picker
            i18n={getDictionary(t)}
            showPreview={false}
            showSkinTones={false}
            autoFocus
            emojiTooltip
            native
            onSelect={handleSelect}
          />
        </div>
      )}
      <Tooltip key="Add Emoji" title={t('Add emoji')}>
        {/* @ts-ignore */}
        <Button
          appearance="knockout"
          ariaLabel={
            isPickerDisplayed ? t('Close emoji picker') : t('Add emoji')
          }
          className={cx('emoji-picker__button', 'button--icon-only')}
          size="small"
          onClick={() => {
            setIsPickerDisplayed(true);
          }}
        >
          {isPickerDisplayed ? (
            <svg
              height="24"
              viewBox="4 1 19 19"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <path
                  d="M12,6 C8.7,6 6,8.7 6,12 C6,15.3 8.7,18 12,18 C15.3,18 18,15.3 18,12 C18,8.7 15.3,6 12,6 Z"
                  fill="#FFE300"
                />
                <path
                  d="M12,6 C15.3,6 18,8.7 18,12 C18,15.3 15.3,18 12,18 C8.7,18 6,15.3 6,12 C6,8.7 8.7,6 12,6 Z M12,6.70588235 C9.08823529,6.70588235 6.70588235,9.08823529 6.70588235,12 C6.70588235,14.9117647 9.08823529,17.2941176 12,17.2941176 C14.9117647,17.2941176 17.2941176,14.9117647 17.2941176,12 C17.2941176,9.08823529 14.9117647,6.70588235 12,6.70588235 Z M9.63529412,13.8705882 C9.77647059,13.7294118 9.98823529,13.7294118 10.1294118,13.8705882 C11.1529412,14.8941176 12.8470588,14.8941176 13.8705882,13.8705882 C14.0117647,13.7294118 14.2235294,13.7294118 14.3647059,13.8705882 C14.5058824,14.0117647 14.5058824,14.2235294 14.3647059,14.3647059 C13.7117647,15.0176471 12.8647059,15.3529412 12,15.3529412 C11.1352941,15.3529412 10.2882353,15.0176471 9.63529412,14.3647059 C9.49411765,14.2235294 9.49411765,14.0117647 9.63529412,13.8705882 Z M10.2352941,9.70588235 C10.7226042,9.70588235 11.1176471,10.1009252 11.1176471,10.5882353 C11.1176471,11.0755454 10.7226042,11.4705882 10.2352941,11.4705882 C9.74798404,11.4705882 9.35294118,11.0755454 9.35294118,10.5882353 C9.35294118,10.1009252 9.74798404,9.70588235 10.2352941,9.70588235 Z M13.7647059,9.70588235 C14.252016,9.70588235 14.6470588,10.1009252 14.6470588,10.5882353 C14.6470588,11.0755454 14.252016,11.4705882 13.7647059,11.4705882 C13.2773958,11.4705882 12.8823529,11.0755454 12.8823529,10.5882353 C12.8823529,10.1009252 13.2773958,9.70588235 13.7647059,9.70588235 Z"
                  fill="#000"
                  fillRule="nonzero"
                />
              </g>
            </svg>
          ) : (
            <Icon name="smiley-face" />
          )}
        </Button>
      </Tooltip>
    </div>
  );
};

export default EmojiPicker;
