import React from 'react';

import { ButtonWithIconLeft } from 'koba/components/Button';
import Icon from 'koba/components/Icon';

interface PostAttachmentButtonsProps {
  openAttachment: (attachmentType: string) => () => void;
  buttonsDisabled: boolean;
  attachmentButtonData: Array<{
    title: string;
    attachmentType: string;
    iconName: string;
    dataQA: string;
  }>;
}

const PostAttachmentButtons: React.FC<PostAttachmentButtonsProps> = ({
  openAttachment,
  buttonsDisabled,
  attachmentButtonData,
}) => {
  const buttons = attachmentButtonData.map(
    ({ title, attachmentType, iconName, dataQA }) => {
      return (
        <ButtonWithIconLeft
          appearance="ghost"
          className=""
          data-qa={dataQA}
          isDisabled={buttonsDisabled}
          key={title}
          size="small"
          onClick={openAttachment(attachmentType)}
        >
          <Icon name={iconName} />
          <span>{title}</span>
        </ButtonWithIconLeft>
      );
    }
  );
  return <>{buttons}</>;
};

export default PostAttachmentButtons;
