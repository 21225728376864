import React, { useState, useRef, MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';
import AttachmentFileInput from 'components/AttachmentFileInput';
import AttachmentVideoInput from 'components/AttachmentVideoInput';

import {
  BREAKPOINT_MD,
  VIDEO_ATTACHMENT,
  IMAGE_ATTACHMENT,
  FILE_ATTACHMENT,
  ACCEPTED_IMAGE_TYPES,
  MAX_FILE_SIZE_MB,
  ACCEPTED_FILE_TYPES,
} from 'utils/constants';
import { useWindowSize } from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

import { useCommunityContext } from 'components/CommunityContext';
import { Attachment } from 'schema/Post/types';
import CommentAttachmentButtons from './CommentAttachmentButtons';
import PostAttachmentButtons from './PostAttachmentButtons';

import styles from './AttachmentButtons.module.scss';

const cx = classNames.bind(styles);

interface AttachmentButtons {
  altText?: string | null;
  attachment?: Attachment | null;
  inputRef?: MutableRefObject<
    HTMLTextAreaElement | HTMLInputElement | undefined
  >;
  isAttachmentRenderedInPortal?: boolean;
  isDisabled?: boolean;
  isIconOnly?: boolean;
  isPostForm?: boolean;
  s3UploadPath: string;
  onAltTextChange?: (text: string) => void;
  onAttachmentClicked?: () => void;
  onChange: (attachment: Attachment | null) => void;
  onFilePickerOpen?: (isExpanded: boolean) => void;
  onUploading?: () => void;
}

const AttachmentButtons: React.FC<AttachmentButtons> = ({
  attachment,
  isAttachmentRenderedInPortal,
  isDisabled,
  isIconOnly,
  isPostForm,
  s3UploadPath,
  altText = '',
  onAltTextChange,
  onChange,
  onUploading,
  onAttachmentClicked,
  inputRef,
  onFilePickerOpen,
}) => {
  const { t } = useTranslation();

  const [attachmentType, setAttachmentType] = useState<
    string | null | undefined
  >(attachment ? attachment.attachmentType : null); // Attachment type can be file | image | video or a mimetype

  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINT_MD;

  const currentRef = useRef<HTMLDivElement>(null);
  const { filestackSecurity } = useCommunityContext();

  const openAttachment = (postAttachmentType) => () => {
    setAttachmentType(postAttachmentType);
    focusInput();
    onAttachmentClicked?.();
  };

  const removeAttachment = () => {
    setAttachmentType(null);
    onChange(null);
    focusInput();
  };

  const handleAttachmentChange = (postAttachment: Attachment) => {
    if (postAttachment && attachmentType) {
      onChange({ attachmentType, ...postAttachment });
    } else {
      onChange(null);
    }
    focusInput();
  };

  const handleAttachmentAltTextChange = (text) => {
    onAltTextChange?.(text);
  };

  const focusInput = () => {
    if (isAttachmentRenderedInPortal && inputRef) {
      inputRef?.current?.focus();
    }
  };

  const renderAttachmentInput = () => {
    const attachmentInputProps = {
      onChange: handleAttachmentChange,
      onClose: removeAttachment,
      uploadingLabel: t(
        'components-attachmentButtons-uploading',
        'Uploading...'
      ),
    };

    switch (attachmentType) {
      case IMAGE_ATTACHMENT:
        return (
          <AttachmentFileInput
            accept={ACCEPTED_IMAGE_TYPES}
            altText={altText}
            attachment={attachment}
            buttonLabel={t('Upload image')}
            clientOptions={filestackSecurity}
            isPostForm={isPostForm}
            maxFileSizeInMb={MAX_FILE_SIZE_MB}
            s3UploadPath={s3UploadPath}
            hasImagePreview
            onAltTextChange={handleAttachmentAltTextChange}
            onFilePickerOpen={onFilePickerOpen}
            onUploading={onUploading}
            {...attachmentInputProps}
          />
        );
      case FILE_ATTACHMENT:
        // TODO: Copy the accepted file types from course-builder
        return (
          <AttachmentFileInput
            accept={ACCEPTED_FILE_TYPES}
            attachment={attachment}
            buttonLabel={t('Upload file')}
            clientOptions={filestackSecurity}
            maxFileSizeInMb={MAX_FILE_SIZE_MB}
            s3UploadPath={s3UploadPath}
            onFilePickerOpen={onFilePickerOpen}
            onUploading={onUploading}
            {...attachmentInputProps}
          />
        );
      case VIDEO_ATTACHMENT:
        return (
          <AttachmentVideoInput
            attachmentUrl={attachment ? attachment.url : ''}
            buttonLabel={t('Add video')}
            cancelButtonLabel={t('Cancel')}
            inputLabel={t('Video URL')}
            {...attachmentInputProps}
          />
        );
      default:
        return null;
    }
  };

  const AttachmentInputPortal = () => {
    if (!isAttachmentRenderedInPortal) {
      return renderAttachmentInput();
    }
    const currentEl = currentRef.current;
    if (!currentEl) {
      return null;
    }
    const portalEl = currentEl
      .closest('.actions')
      ?.querySelector('.attachment-input__portal');

    if (!portalEl) return null;

    return (
      portalEl &&
      ReactDOM.createPortal(
        <div className={cx('attachment-input__container')}>
          {renderAttachmentInput()}
        </div>,
        portalEl
      )
    );
  };

  const buttonsDisabled = isDisabled || !!attachmentType;
  const isMobileCommentView = isIconOnly && isMobile;

  const attachmentButtonData = [
    {
      tooltipTitle: t('Upload image'),
      title: t('IMAGE'),
      attachmentType: IMAGE_ATTACHMENT,
      iconName: 'image',
      dataQA: 'create-post-image__button',
    },
    {
      tooltipTitle: t('Add video'),
      title: t('VIDEO'),
      attachmentType: VIDEO_ATTACHMENT,
      iconName: 'content-video',
      dataQA: 'create-post-video__button',
    },
    {
      tooltipTitle: t('Upload file'),
      title: t('FILE'),
      attachmentType: FILE_ATTACHMENT,
      iconName: 'page',
      dataQA: 'create-post-file__button',
    },
  ];

  return (
    <>
      <div className={cx('attachment')} data-qa="attachment">
        <div className={cx('attachment__input')} ref={currentRef}>
          {/* @ts-expect-error Server Component */}
          {!isMobileCommentView && <AttachmentInputPortal />}
        </div>
        <div
          className={cx('attachment__buttons', {
            'attachment__buttons--icon-only': isIconOnly,
          })}
        >
          {isIconOnly ? (
            <CommentAttachmentButtons
              attachmentButtonData={attachmentButtonData}
              buttonsDisabled={buttonsDisabled}
              openAttachment={openAttachment}
            />
          ) : (
            <>
              {!attachment && (
                <PostAttachmentButtons
                  attachmentButtonData={attachmentButtonData}
                  buttonsDisabled={buttonsDisabled}
                  openAttachment={openAttachment}
                />
              )}
            </>
          )}
        </div>

        {isMobileCommentView && (
          <div className={cx('attachment__mobile-input')}>
            {renderAttachmentInput()}
          </div>
        )}
      </div>
    </>
  );
};

// Apparently we need React.memo otherwise the loading indicator on image uploads doesn't appear...
export default React.memo(AttachmentButtons);
