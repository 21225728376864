import React from 'react';

import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import { Caption, P } from 'koba/components/Typography';
import { TFunction } from 'next-i18next';
import { FileSizeLabel } from 'components/FileSizeLabel';
import styles from './AttachmentFileInput.module.scss';

const cx = classNames.bind(styles);

interface FilePreviewProps {
  fileName: string;
  fileSizeInBytes: number;
  onRemove(): void;
  t: TFunction; // Would be better to pass in the translated strings used here
}

const FilePreview: React.FC<FilePreviewProps> = ({
  fileName,
  fileSizeInBytes,
  onRemove,
  t,
}) => (
  <div
    aria-live="polite"
    className={cx('file-container')}
    id="file-attachment-preview"
    role="region"
    title={t('File Preview')}
  >
    <P className={cx('file-container__name')}>
      {fileName}
      {/* @ts-ignore - Can remove this once we convert the Caption component */}
      <Caption>
        {' '}
        <FileSizeLabel byteCount={fileSizeInBytes} t={t} />
      </Caption>
    </P>
    {/* @ts-ignore - Can remove this once we convert the Button component */}
    <Button
      appearance="knockout"
      ariaLabel={t('Remove attachment')}
      className={cx('remove-file-button')}
      size="x-small"
      onClick={onRemove}
    >
      <Icon name="x-dismiss" />
    </Button>
  </div>
);

export default FilePreview;
