import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Icon from 'koba/components/Icon';
import { getReactors } from 'helpers/reactionHelpers';
import ReactionButton from 'components/ReactionButton';
import { ButtonWithIconLeft } from 'koba/components/Button';

import { Post } from 'types/post';
import { ReplyEdgeFieldsFragment as Comment } from '__generated__/graphql/legacy/graphql';

import { REACTION_TYPES } from 'utils/constants';

import PostSpaceBadge from './PostSpaceBadge';

import styles from '../Post.module.scss';

const cx = classNames.bind(styles);

interface PostHeaderProps {
  showSpaceTag: boolean;
  hasReacted: boolean;
  post: Post | Comment;
  goToPost: () => void;
  totalCommentCount: number;
  postReactionCount: number;
  communityId: string;
  spaceId: string;
  spaceName: string;
  spaceIconName: string;
}

const PostFooter: React.FC<PostHeaderProps> = ({
  showSpaceTag,
  hasReacted,
  post,
  postReactionCount,
  goToPost,
  totalCommentCount,
  communityId,
  spaceId,
  spaceName,
  spaceIconName,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx('post__footer', {
        'is-spaces-enabled': showSpaceTag,
      })}
    >
      <div>
        <ReactionButton
          hasReacted={hasReacted}
          id={post.id}
          isReactingTo="post"
          postReactionCount={postReactionCount}
          reactionType={REACTION_TYPES.LIKE_POST}
          reactors={getReactors(post)}
          t={t}
        />
        <ButtonWithIconLeft
          appearance="knockout"
          data-qa="main-comments_text"
          size="small"
          onClick={goToPost}
        >
          <Icon name="discussions" />
          <span>{t('{{count}} comment', { count: totalCommentCount })}</span>
        </ButtonWithIconLeft>
      </div>
      {showSpaceTag && spaceId && (
        <PostSpaceBadge
          communityId={communityId}
          spaceIconName={spaceIconName}
          spaceId={spaceId}
          spaceName={spaceName}
        />
      )}
    </div>
  );
};

export default PostFooter;
