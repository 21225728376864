import React from 'react';

import classNames from 'classnames/bind';
import Icon from 'koba/components/Icon';
import { TFunction } from 'next-i18next';
import Label from 'koba/components/Label';
import { Tooltip } from '@thinkific/toga-react';
import Input from 'koba/components/Input';
import styles from './AttachmentFileInput.module.scss';

const cx = classNames.bind(styles);

interface AttachmentAltTextInputProps {
  value: string;
  onChange(text: string): void;
  t: TFunction; // Would be better to pass in the translated strings used here
  isDisabled?: boolean;
}

export const AttachmentAltTextInput: React.FC<AttachmentAltTextInputProps> = ({
  value,
  onChange,
  t,
  isDisabled = false,
}) => {
  return (
    <>
      <Label
        className={cx('file-input__label-with-tooltip')}
        htmlFor="image-alt"
      >
        {t('Alternative text (optional)')}
        <Tooltip
          className={cx('file-input__tooltip')}
          title={t(
            'Alternative text describes your image to people with visual impairments'
          )}
        >
          <div className={cx('file-input__tooltip-icon')}>
            <Icon name="circle-outline-info" />
          </div>
        </Tooltip>
      </Label>
      <Input
        extraProps={{ maxLength: 100 }}
        handleChange={onChange}
        id="image-alt"
        initValue={value}
        isDisabled={isDisabled}
        name="image-alt"
        type="text"
        wrapperClass={cx('file-input__image-alt')}
      />
    </>
  );
};
export default AttachmentAltTextInput;
