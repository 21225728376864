import React, { Component } from 'react';

import classNames from 'classnames/bind';
import { withTranslation, TFunction } from 'next-i18next';
import Button from 'koba/components/Button';
import Input from 'koba/components/Input';
import { Caption } from 'koba/components/Typography';
import ReactPlayer from 'react-player/lazy';
import { WithTranslation } from 'react-i18next';
import { Attachment } from 'schema/Post/types';
import { isLoomUrl } from 'utils/urlParser';
import styles from './AttachmentVideoInput.module.scss';
import VideoPreview from './VideoPreview';

const cx = classNames.bind(styles);

interface AttachmentVideoInputProps extends WithTranslation {
  attachmentUrl?: string | null;
  buttonLabel: string;
  cancelButtonLabel: string;
  inputLabel: string;
  t: TFunction;
  onChange: (attachment?: Attachment) => void;
  onClose: () => void;
}

type AttachmentVideoInputState = {
  videoUrl: string;
  isVideoUploaded: boolean;
  errorMessage: string;
};

class AttachmentVideoInput extends Component<
  AttachmentVideoInputProps,
  AttachmentVideoInputState
> {
  constructor(props: AttachmentVideoInputProps) {
    super(props);
    const { attachmentUrl } = props;
    this.state = {
      videoUrl: attachmentUrl || '',
      isVideoUploaded: !!attachmentUrl,
      errorMessage: '',
    };
  }

  handleUploadVideo = () => {
    const { onChange } = this.props;
    const { videoUrl } = this.state;
    if (this.handleValidation()) {
      this.setState({ isVideoUploaded: true });

      onChange({
        // We need to nullify these, as it is only used for file/image attachments
        fileName: undefined,
        fileSizeInBytes: undefined,
        filestackId: undefined,
        s3Key: undefined,
        s3Bucket: undefined,
        // Both are needed to display the video in the post, and the preview
        // in the edit modal
        url: videoUrl,
        attachmentUrl: videoUrl,
      });
    }
  };

  handleRemoveVideo = () => {
    const { onChange } = this.props;
    this.setState({ isVideoUploaded: false, videoUrl: '', errorMessage: '' });
    onChange(undefined);
  };

  handleChange = (value) => {
    this.setState({ videoUrl: value });
  };

  handleValidation = () => {
    const { videoUrl } = this.state;
    const { t } = this.props;
    let errorMessage;
    let urlIsValid = true;

    const isPlayableUrl = ReactPlayer.canPlay(videoUrl) || isLoomUrl(videoUrl);

    if (!isPlayableUrl) {
      urlIsValid = false;
      errorMessage = t('Invalid URL');
    }
    this.setState({ errorMessage });
    return urlIsValid;
  };

  disableAddVideoButton = () => {
    const { videoUrl } = this.state;
    return videoUrl.length === 0 || !videoUrl;
  };

  render() {
    const {
      buttonLabel,
      onClose,
      cancelButtonLabel,
      inputLabel,
      attachmentUrl,
      t,
    } = this.props;
    const { isVideoUploaded, videoUrl, errorMessage } = this.state;

    if (isVideoUploaded) {
      return (
        <VideoPreview videoUrl={videoUrl} onRemove={this.handleRemoveVideo} />
      );
    }

    return (
      !attachmentUrl && (
        <div className={cx('video-attachment__wrapper')}>
          <Input
            ariaControls="video-attachment-preview"
            extraClasses={cx(errorMessage ? 'input--failure' : '')}
            handleChange={this.handleChange}
            id="video-url"
            initValue={videoUrl}
            label={inputLabel}
            name="video-url"
            placeholder="https://www.example.com"
            type="url"
            wrapperClass={cx('video-attachment__input')}
            required
          />
          {errorMessage && (
            <div role="alert">
              <Caption className="error-message">{errorMessage}</Caption>
            </div>
          )}
          <Caption>
            {t(
              'components-attachmentVideoInput-supported_video_platforms_tip',
              'Supported Platforms: YouTube, Vimeo, Loom'
            )}
          </Caption>
          <div className={cx('video-attachment__action-buttons')}>
            <Button appearance="ghost" size="small" onClick={onClose}>
              {cancelButtonLabel}
            </Button>
            <Button
              isDisabled={this.disableAddVideoButton()}
              size="small"
              onClick={this.handleUploadVideo}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      )
    );
  }
}

export default withTranslation()(AttachmentVideoInput);
