import { gql } from '@apollo/client';

import { POST_FRAGMENT } from 'utils/queries';

export const CREATE_POST_MUTATION = gql`
  mutation CreatePost(
    $discussionableId: ID!
    $title: String!
    $content: String
    $spaceId: ID
    $postAttachment: PostAttachmentInput
    $mentionedUserIds: [ID!]
  ) {
    createPost(
      input: {
        discussionableId: $discussionableId
        title: $title
        content: $content
        communitySpaceId: $spaceId
        postAttachmentInput: $postAttachment
        mentionedUserIds: $mentionedUserIds
      }
    ) {
      post {
        id
      }
      userErrors {
        code
        message
      }
    }
  }
`;

export const EDIT_POST_MUTATION = gql`
  mutation EditPost($input: EditPostInput!, $numberOfReactions: Int!) {
    editPost(input: $input) {
      userErrors {
        code
        message
      }
      post {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const DELETE_POST_MUTATION = gql`
  mutation DeletePost($id: ID!) {
    deletePost(input: { id: $id }) {
      userErrors {
        code
        message
      }
    }
  }
`;

export const FOLLOW_POST_MUTATION = gql`
  mutation FollowPost($id: ID!, $numberOfReactions: Int!) {
    followPost(input: { id: $id }) {
      userErrors {
        code
        message
      }
      post {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const UNFOLLOW_POST_MUTATION = gql`
  mutation UnfollowPost($id: ID!, $numberOfReactions: Int!) {
    unfollowPost(input: { id: $id }) {
      userErrors {
        code
        message
      }
      post {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const PIN_POST_MUTATION = gql`
  mutation PinPost($id: ID!, $numberOfReactions: Int!) {
    pinPost(input: { id: $id }) {
      userErrors {
        code
        message
      }
      post {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const UNPIN_POST_MUTATION = gql`
  mutation UnpinPost($id: ID!, $numberOfReactions: Int!) {
    unpinPost(input: { id: $id }) {
      userErrors {
        code
        message
      }
      post {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const TOGGLE_REACTION_ON_POST_MUTATION = gql`
  mutation ToggleReactionOnPost(
    $id: ID!
    $reactionType: PostReactionType!
    $numberOfReactions: Int!
  ) {
    toggleReactionOnPost(input: { id: $id, reactionType: $reactionType }) {
      userErrors {
        code
        message
      }
      post {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;
