import React from 'react';

import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import { TFunction } from 'next-i18next';
import styles from './AttachmentFileInput.module.scss';

const cx = classNames.bind(styles);

interface ImagePreviewProps {
  imageName: string;
  imageUrl: string;
  onRemove(): void;
  t: TFunction; // Would be better to pass in the translated strings used here
}

const ImagePreview: React.FC<ImagePreviewProps> = ({
  imageName,
  imageUrl,
  onRemove,
  t,
}) => (
  <div className={cx('file-input__image-container')}>
    <div
      aria-live="polite"
      className={cx('file-input__image-preview')}
      id="file-attachment-preview"
      role="region"
      title={t('Image Preview')}
    >
      <img
        alt={`Preview of ${imageName}`}
        className={cx('file-input__image')}
        src={imageUrl}
      />
      <Button
        appearance="default"
        ariaLabel={t('Remove attachment')}
        className={cx('remove-image-button')}
        onClick={onRemove}
      >
        <Icon name="x-dismiss" />
      </Button>
    </div>
  </div>
);
export default ImagePreview;
