import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Label from '../Label';

import styles from './Input.module.scss';

const cx = classNames.bind(styles);

class Input extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    value: '',
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { initValue } = this.props;

    if (initValue !== '') {
      this.setState({ value: initValue.toString() });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update the input state when you change the value from outside
    this.setState({ value: nextProps.initValue });
  }

  handleValueFieldChange = (e) => {
    const { handleChange, validator } = this.props;

    const newValue = e.target.value;
    let isValid = true;
    if (validator && !validator(newValue)) {
      isValid = false;
    }
    if (isValid) {
      this.setState({ value: newValue });
      if (handleChange) handleChange(e?.target?.value);
    }
  };

  sanitizeState = (state) => {
    const sanitizedState = {};
    Object.keys(state).forEach((key) => {
      sanitizedState[key] = state[key].trim();
    });
    return sanitizedState;
  };

  handleKeyDown = (e) => {
    const { handleKeyDown } = this.props;

    handleKeyDown(e, this.sanitizeState(this.state));
  };

  handleKeyPress = (e) => {
    const { onEnter } = this.props;

    if (e.key === 'Enter' && onEnter !== null) {
      onEnter(e, this.sanitizeState(this.state));
    }
  };

  render() {
    const {
      ariaControls,
      hasError,
      id,
      isDisabled,
      name,
      type,
      placeholder,
      extraClasses,
      extraProps,
      label,
      handleBlur,
      hasAutoFocus,
      hasNoGroup,
      wrapperClass,
      formText,
    } = this.props;

    const { value } = this.state;

    let inputLabel = label;
    if (typeof label === 'string') {
      inputLabel = (
        <Label className={cx('input__label')} htmlFor={id}>
          {label}
        </Label>
      );
    }

    const inputClasses = cx('input__box--input', 'form-control', extraClasses, {
      error: hasError,
    });

    const formTextClasses = cx('form-text', {
      'error-message': hasError,
    });

    return (
      <div
        className={cx('input__box', wrapperClass, {
          'form-group': !hasNoGroup,
        })}
      >
        {inputLabel}
        {type === 'textarea' ? (
          <textarea
            autoFocus={hasAutoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            className={inputClasses}
            data-qa={`modal__text-area-${name}`}
            disabled={isDisabled}
            id={id}
            name={name}
            placeholder={placeholder}
            ref={(node) => {
              this.input = node;
            }}
            value={value}
            onBlur={handleBlur}
            onChange={this.handleValueFieldChange}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            {...extraProps}
          />
        ) : (
          <input
            aria-controls={ariaControls}
            autoFocus={hasAutoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            className={inputClasses}
            data-qa={`modal__input-${name}`}
            disabled={isDisabled}
            id={id}
            name={name}
            placeholder={placeholder}
            ref={(node) => {
              this.input = node;
            }}
            type={type}
            value={value}
            onBlur={handleBlur}
            onChange={this.handleValueFieldChange}
            onKeyDown={this.handleKeyDown}
            onKeyPress={this.handleKeyPress}
            {...extraProps}
          />
        )}
        {formText && <span className={formTextClasses}>{formText}</span>}
      </div>
    );
  }
}

Input.propTypes = {
  ariaControls: PropTypes.string,
  extraClasses: PropTypes.string,
  extraProps: PropTypes.object,
  formText: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  hasAutoFocus: PropTypes.bool,
  hasClearButton: PropTypes.bool,
  hasError: PropTypes.bool,
  hasNoGroup: PropTypes.bool,
  id: PropTypes.string,
  initValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisabled: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validator: PropTypes.func,
  wrapperClass: PropTypes.string,
  onEnter: PropTypes.func,
};

Input.defaultProps = {
  ariaControls: null,
  handleBlur: null,
  handleKeyDown: () => {},
  validator: () => true,
  handleChange: null,
  hasClearButton: false,
  hasError: false,
  name: '',
  type: 'text',
  initValue: '',
  isDisabled: false,
  placeholder: '',
  extraProps: {},
  extraClasses: '',
  label: null,
  id: null,
  onEnter: null,
  hasAutoFocus: false,
  hasNoGroup: false,
  wrapperClass: null,
  formText: null,
};

export default Input;
