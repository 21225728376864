import { TFunction } from 'next-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getDictionary = (t: TFunction) => ({
  search: t('Search'),
  clear: t('Clear'),
  notfound: t('No Emoji Found'),
  skintext: t('Choose your default skin tone'),
  categories: {
    search: t('Search Results'),
    recent: t('Frequently Used'),
    people: t('Smileys & People'),
    nature: t('Animals & Nature'),
    foods: t('Food & Drink'),
    activity: t('Activity'),
    places: t('Travel & Places'),
    objects: t('Objects'),
    symbols: t('Symbols'),
    flags: t('Flags'),
    custom: t('Custom'),
  },
  categorieslabel: t('Emoji categories'),
  skintones: {
    1: t('Default Skin Tone'),
    2: t('Light Skin Tone'),
    3: t('Medium-Light Skin Tone'),
    4: t('Medium Skin Tone'),
    5: t('Medium-Dark Skin Tone'),
    6: t('Dark Skin Tone'),
  },
});

export default getDictionary;
