import React from 'react';
import { Tooltip } from '@thinkific/toga-react';
import { TFunction } from 'next-i18next';
import { useCommunityContext } from 'components/CommunityContext';

interface ReactionHoverListProps {
  children: React.ReactElement;
  hasReacted: boolean;
  postReactionCount: number;
  reactionsToShow: number;
  reactors: Array<{ fullName: string; id?: string }>;
  t: TFunction;
}

const ReactionHoverList: React.FC<ReactionHoverListProps> = ({
  children,
  hasReacted,
  postReactionCount,
  reactionsToShow,
  reactors,
  t,
}) => {
  const { currentUser } = useCommunityContext();
  let reactorList = reactors.slice();
  const isCurrentUserInList =
    currentUser && !!reactors.find((r) => r.id === currentUser.id);

  if (hasReacted && currentUser) {
    if (isCurrentUserInList) {
      // Need to remove their name and put You at the front
      reactorList = reactorList.filter((r) => r.id !== currentUser.id);
      reactorList.unshift({ fullName: t('You') });
    } else {
      // Put You at the front, and pop someone off the back
      reactorList.unshift({ fullName: t('You') });
      if (postReactionCount >= reactionsToShow) {
        reactorList.pop();
      }
    }
  }

  if (postReactionCount > reactionsToShow) {
    const hiddenReactors = postReactionCount - reactionsToShow;
    const plusMore = {
      fullName: t('+ {{nameCount}} more', { nameCount: hiddenReactors }),
    };
    reactorList = reactorList.slice(0, reactionsToShow).concat(plusMore);
  }

  const reactorNames = reactorList.map((r) => r.fullName).join(', ');

  return (
    <Tooltip enterDelay={400} title={reactorNames}>
      {children}
    </Tooltip>
  );
};

export default ReactionHoverList;
