import ReactPlayer from 'react-player/lazy';
import React from 'react';
import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import { parseLoomId } from 'utils/urlParser';
import styles from './AttachmentVideoInput.module.scss';
import LoomPreview from './LoomPreview';

const cx = classNames.bind(styles);

interface VideoPreviewProps {
  videoUrl: string;
  onRemove: () => void;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({ videoUrl, onRemove }) => {
  const loomId = parseLoomId(videoUrl);

  let player: React.ReactNode = null;
  if (loomId) {
    player = <LoomPreview loomId={loomId} />;
  } else {
    player = (
      <ReactPlayer height="320px" url={videoUrl} width="100%" controls />
    );
  }
  return (
    <div
      aria-live="polite"
      className={cx('video-attachment__preview-container')}
      id="video-attachment-preview"
      role="region"
      title="Video Preview"
    >
      {player}
      <Button
        appearance="default"
        ariaLabel="Remove video"
        className={cx('remove-video-button')}
        onClick={onRemove}
      >
        <Icon name="x-dismiss" />
      </Button>
    </div>
  );
};

export default VideoPreview;
